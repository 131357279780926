.hero {
	background-color: #fff;
  &.amazon-hero {
    background: linear-gradient(180deg, #7657EE 57.5%, rgba(255,255,255,1) 100%);
    color: #fff;
    padding-bottom: 6rem;

    h1, h2, h3 {
      color: #fff;
    }

    .section-inner {
      padding-top: 10rem;
      padding-bottom: 6rem;
    }
  }
	.hero-cta {
		padding: 0;
		justify-content: flex-start;

    &.amazon-cta {
      justify-content: center;
      
      h3 {
        color: #fff;
      }
    }
	}

	.section-inner {
		padding-top: $hero--padding-t__mobile;
		padding-bottom: $hero--padding-b__mobile;
	}
}

.hero-cta {
	padding: 0 0 2em 0;
	display: flex;
	align-items: center;
	justify-content: center;

  &.amazon-cta {
    padding: 0;
    background: rgb(0, 66, 37);
  }

	> .hero-cta-title {
		padding-right: 1em;
	}

  &.amazon-cta h3 {
    color: #fff;
  }

	.form-submit {
		position: absolute;
		right: 0;
		z-index: 2;
		top: calc(50% - 6px);
		position: absolute;
		right: 15px;
		cursor: pointer;
	}
}


.hero-inner {
	padding-bottom: 0;
	// Affects only hero full
	> .hero-content + .hero-figure,
	> .hero-figure + .hero-content {
		margin-top: $hero--inner-padding-v_mobile;
	}

	> .hero-figure {

		> a {
			display: inline-flex;
			// prevents stretching
			align-items: center;
			vertical-align: top;
		}
	}

	.hero-detail-image-2 {
		display: none;
	}
}

.hero-install {
	display: none;
}

@include media('<=xlarge') {
	.hero-detail-image {
		display: none;
	}

  .amazon-hero .hero-detail-image {
    display: none;
  }

	.hero-img {
		right: -185px;
	}
}

@include media('<=xlarge') {

  .hero {
    &.amazon-hero {
      padding-bottom: 2rem;

      .section-inner {
        padding-top: 2rem;
        padding-bottom: 0;
      }
    }
  }
  .amazon-hero .hero-detail-image {
    display: none;
  }

  .amazon-hero .hero-inner .hero-detail-image-2 {
    display: block;
    margin: 0 auto;
		max-width: 400px;
		padding-top: 1rem;
  }
}


@include media( '<=medium' ) {

  .hero-subtitle-content-container {
    padding-bottom: 6px;
  }

	.hero-inner .hero-detail-image-2 {
		display: block;
		margin: 0 auto;
		max-width: 400px;
		padding-top: 1rem;
	}

	.hero-install {
		display: inline-flex;
		margin-top: 2rem;
	}
	.hero-content {

		.hero-cta {
			padding-top: 0rem;
			> .hero-cta-title {
				> h3 {
					padding-top: 1rem;
				}
			}
		}
	}

	.hero-cta {
		display: block;
		text-align: center;
		max-width: 80%;
		margin: 0 auto;
		padding: 2em 0;

    &.amazon-cta {
      max-width: 100%;
    }

		> .hero-cta-title {
			padding-right: 0;

			> h3 {
				margin: 0;
			}
		}
	}


	.hero {
		.hero-subtitle {
			font-size: 30px;
		}

		.split-wrap {

			.split-item {

				.split-item-content {
					margin-bottom: $hero--inner-padding-v_mobile;
				}
			}

			&.invert-mobile {

				.split-item {

					.split-item-image {
						margin-bottom: $hero--inner-padding-v_mobile;
					}
				}
			}
		}
	}
}

@include media( '>medium' ) {

	.hero {

		.section-inner {
			padding-top: $hero--padding-t__desktop;
			padding-bottom: $hero--padding-b__desktop;
		}
	}

	.hero-inner {

		// Affects only hero full
		> .hero-content + .hero-figure,
		> .hero-figure + .hero-content {
			margin-top: $hero--inner-padding-v_desktop;
		}
	}
}
