.site-header {
	position: absolute !important;
	top: 0;
	width: 100%;
	z-index: 10 !important;
	background: rgba(255,255,255,0.75);

  &.amazon-header {
    background: #FFFFFF;
    color: #fff;
    position: fixed !important;
    box-shadow: 0px 4px 26px rgb(0 0 0 / 10%);

    h3 {
      color: #fff;
    }
  }

	+ .site-content {

		.section:first-of-type {
			padding-top: $header-height__mobile;
      padding-bottom: 2em;
		}
	}

	.brand {
		margin-right: $header-nav--padding-h;
	}	
}

.site-header-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
		height: $header-height__mobile;
}

.header-nav {
	flex-grow: 1;

	.header-nav-inner {
		display: flex;
		flex-grow: 1;

		.button {
			border-radius: 3px;
			font-weight: bold;
			padding: 6px 16px;
			font-size: 16px;

			&:hover {
				color: #fff;
			}
		}
	}

  ul {
    display: flex;
    align-items: center;

    &.firebaseui-idp-list {
      justify-content: center;
    }
    flex-grow: 1;
    white-space: nowrap;
    margin-bottom: 0;

    &:first-of-type {
      flex-wrap: wrap;
    }
  }

  li {
    + .header-button {
      margin-left: $header-nav--padding-h;
    }
  }

  a:not(.button) {
    display: block;
    color: #ED7954;
    text-transform: $link-header--transform;
    padding: 0 $header-nav--padding-h;

    .invert-color & {
      @include anchor-aspect(header, inverse);
    }
  }

  &.amazon-header-nav {
    a:not(.button) {
      color: #7657EE;
    }

    .button-secondary {
      box-shadow: 0px 4px 8px rgba(10, 22, 70, 0.15);
      padding: 16px 48px;
      background-color: #fff;
      font-size: 1rem;
      font-weight: 300;
      border-radius: 9999px;
      border: 1px solid #7657EE;
      color: #7657EE;
    }

    .header-nav-inner {
      .button {
        &:hover {
          color: #7657EE;
          border-color: #7657EE;
          box-shadow: 0px 6px 8px rgba(10, 22, 70, 0.15);
        }
      }
    }
  }

	a.button {
		margin-left: $header-nav--padding-h;
	}
}

.header-nav-center:first-of-type {
	flex-grow: 1;
	justify-content: flex-end;
}

.header-nav-right {
	justify-content: flex-end;

	+ .header-nav-right {
		flex-grow: 0;
	}
}

.header-nav-toggle {
	display: none;
}

@include media( '<=medium' ) {


	.header-nav {
		.header-nav-inner {
			button {
				padding: 6px 12px;
			}
		}
	}
	.header-nav h4 {
		font-size: 16px;
		// max-width: 100px;
		text-align: center;
		white-space: pre-wrap;
		line-height: 1;
	}

	.header-nav-toggle {
		display: block;

		// Header navigation when the hamburger is a previous sibling
		+ .header-nav {
			flex-direction: column;
			position: absolute;
			left: 0;
			right: 0;
			top: 100%;
			z-index: 9999;
			background: rgba(255,255,255,1);
			max-height: 0;
	        opacity: 0;
	        overflow: hidden;
	        transition: max-height .25s ease-in-out, opacity .15s;

			&.is-active {
				opacity: 1;
			}

			.header-nav-inner {
				flex-direction: column;
				padding: $header-nav--padding-v__mobile;
			}

			ul {
				display: block;
				text-align: center;

			    a:not(.button) {
            display: inline-flex;
            padding-top: $header-nav--padding-v__mobile * 0.5;
            padding-bottom: $header-nav--padding-v__mobile * 0.5;
			    }
			}

			a.button {
				margin-left: 0;
				margin-top: $header-nav--padding-v__mobile * 0.5;
				margin-bottom: $header-nav--padding-v__mobile * 0.5;
			}
		}
	}
}

@include media( '>medium' ) {

	.site-header {

		+ .site-content {

			.section:first-of-type {
				padding-top: $header-height__desktop;
			}
		}
	}

	.site-header-inner {
		height: $header-height__desktop;
	}
}
