.why-relish.has-bg-color {
  background-color: rgba(255,255,255,0.75);
  color: get-color(dark, 1);

  .how-it-works-description {
    text-align: center;
    margin: 0 auto;
    width: 66.66%;

    > div {
      font-size: 24px;
      padding-right: 0;
      padding-bottom: 1em;
    }
  }
  .section-header {
    padding-bottom: 2em;
  }

  .section-inner {
    padding-bottom: 0;
  }
}

@include media('<large') {
  .why-relish.has-bg-color {
    background-color: rgba(255,255,255,0.5);
    color: get-color(dark, 1);
  
    .how-it-works-description {
      margin: 0 auto;
  
      > div {
        font-size: 20px;
        padding-right: 0;
        padding-bottom: 1em;
      }
    }

    img {
      max-width: 300px;
      margin: 0 auto;
    }
  }
}