.add-referral {
  .add-referral-form {
    margin: 0 auto;
    max-width: 600px;
    background-color: rgba(255,255,255,0.75);

    .form-row {
      display: flex;
      justify-content: space-between;
    }

    .form-section {
      padding-bottom: 2em;
      width: 100%;

      label {
        font-weight: bold;
      }

      input {
        display: block;
        padding: 8px 16px;
        width: 100%;
        border-radius: 3px;
        box-sizing: border-box;
        border: 1px solid get-color('light', 2);
        box-shadow: none;

        &::placeholder {
          color: color(input-placeholder);
        }
      }

      textarea {
        width: 100%;
        height: 150px;
        padding: 8px 16px;
        box-sizing: border-box;
        border: 1px solid get-color('light', 2);
        border-radius: 3px;
        resize: none;
      }
    }
  }
}