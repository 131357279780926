.how-it-works {
  .section-header {
    padding-bottom: 2em;
  }

  // &.amazon-how-it-works {
  //   // padding-top: 0;
  // }

  .screen {
    padding-left: 1em;
    border-left: 3px solid get-color(dark, 1);
  }

  .active-screen {
    border-left: 3px solid #ED7954;
  }

  .checklist__item-link::after {
    position: absolute;
    top: 20px;
    right: 1em;
    font: var(--fa-font-solid);
    content: '\f078';
    font-size: 16px;
  }
  
  .checklist__item-link.checklist__item-link--active::after {
    color: #ED7954;
    -webkit-transform: translateY(-50%) rotate(-90deg);
    transform: translateY(-50%) rotate(-90deg);
  }

  .how-it-works-desktop {
    display: flex;
  }

  .how-it-works-mobile {
    display: none;
  }
}

.dots {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
}

.dot {
  width: 16px;
  height: 16px;
  background: #fff;
  border: 1px solid #000;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.active-dot {
  border: 1px solid #ED7954;
  background-color: #ED7954;
  cursor: default;
}

@include media('<large') {
  .how-it-works {
    .how-it-works-desktop {
      display: none;
    }
  
    .how-it-works-mobile {
      display: block;

      .screen {
        border-left: none;
        padding: 0;
      }

      h3 {
        font-size: 22px;
        text-align: center;
      }
    }

    .how-it-works-description {
      display: none;
    }

    .section-header {
      padding-bottom: 1em;
    }
  }
}
