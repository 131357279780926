.overview-section {
  > .overview-inner {
    padding-bottom: 0;

    > .overview-container {
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      > .overview-column {
        max-width: 400px;

        > .overview-column-title {
          color: get-color(light, 1);
          margin: 0 0 16px;
        }

        > .overview-column-img {
            background-color: get-color(light, 1);
            border-radius: 50%;
            width: 100px;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
        }
      }
    }
  }
}

@include media( '<medium' ) {
  .overview-section {
    > .overview-inner {
  
      > .overview-container {
        > .overview-column {
          > h4.overview-column-title {
            line-height: 1.1;
            font-size: 20px;
          }
        }
      }
    }
  }  
}