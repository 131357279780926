.team-container {
  .section-inner {
    background-color: rgba(255,255,255,0.75);
    border-radius: 3px;
  }

  .team-cards {
    display: flex;
    justify-content: space-evenly;

    .team-card {
      padding: 2em;
    }

    .team-image {
      width: 200px;
      display: block;
      margin: 0 auto;
      border-radius: 50%;
      box-shadow: 0 2px 6px 0 rgb(0 0 0 / 15%)
    }
  
    .team-name {
      padding-top: 1em;
      font-size: 24px;
      color: get-color(dark, 1);
    }
  
    .team-title {
      color: get-color(light, 3);
    }
  }
}

.mission-container {
  a {
    color: initial;
    text-decoration: underline;
  }
  .section-inner {
    background-color: rgba(255,255,255,0.75);
    border-radius: 3px;
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 0;

    div, p {
      color: get-color(dark, 1);
      font-size: 22px;
      line-height: 1.4;
    }

    .mission-title {
      text-align: left;
      padding-bottom: 2em;
      h1 {
        margin-bottom: 0;
        text-align: left;
      }
      h4 {
        margin-top: 8px;
        color: get-color(light, 3);
      }
    }

    ol {
      padding: 1em;
    }

    li {
      padding-left: 1em;
      margin-left: 1em;
    }
  }

  .mission-profile-img-wrapper img {
    width: 100px;
    border-radius: 50%;
    position: relative;
    top: -25%;
  }

  .mission-byline {
    padding-left: 1em;
  }

  .mission-subtitle {
    padding-top: 1em;
  }
}

@include media( '<=medium' ) {
  .team-container .team-cards {
    display: block;
  }
}