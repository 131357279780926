.main-index{
   background: #F7F7F7;
   height: 100vh;
   overflow-y:auto;
/*color class */
.bg-71D8A4 {
  background: #71D8A4;
}
.bg-BD95FF {
  background: #BD95FF;
}
.bg-FF8F6B {
  background: #FF8F6B;
}

/*tabs*/
.tabs{
  .tab-links{
    a{
      background: #FFFFFF;
      border-radius: 10px;
      padding: 14px 35px;
      font-weight: 600;
      font-size: 18px;
      color: #252525;
      text-decoration: none;
      &.active{
        background: #ED7954;
        color: #fff;
      }
    }
  }
  .menu-links{
    a {
      color: #ED7954;
      font-size: 16px;
    }
  }
}

.edit-section {
  margin-bottom: 8rem;
}

.dropdown-select {
  position: relative;
  width: 150px;
  margin: 0 auto;
  span{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid rgba(0, 0, 0, 0.18);
    border-radius: 8px;
    padding: 9px 15px;
    font-weight: 500;
    font-size: 16px;
    color: rgb(0, 0, 0);
    cursor: pointer;
  }
  .open-dropdown {
    position: absolute;
    width: 0;
    background: #FFFFFF;
    box-shadow: 0px 4px 26px rgb(0 0 0 / 10%);
    border-radius: 10px;
    right: 0;
    left: 0;
    overflow: hidden;
    max-height: 400px;
    overflow: scroll;
    opacity: 0;
    height: 0;
    transition: 0.5s;
    margin: auto;
    z-index: 2;
    ul{
      list-style: none;
      li{
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #252525;
        border-bottom: 1px solid #EBEBEB;
        text-align: left;
        padding: 10px 14px;
        margin-bottom: 0;
        cursor: pointer;
        &:hover{
          color: #ED7954;
        }
        &:last-child{
          border-bottom: none;
        }
        &.active{
          border-left: 5px solid #ED7954;
        }
      }
    }
  }
  &:hover{
    .open-dropdown {
      width: 116px;
      opacity: 1;
      height: auto;
    }
  }

  &.header-dropdown-select span {
    justify-content: flex-start;
  }
}

.user-img {
  position: relative;
  cursor: pointer;
  text-align: center;
  span,img{
    width: 45px;
    height: 45px;
    border-radius: 40px;
    color: #fff;
  }
  .hover-box-open {
    background: #FFFFFF;
    box-shadow: 0px 4px 40px rgb(0 0 0 / 24%);
    border-radius: 12px;
    padding: 0;
    position: absolute;
    text-align: left;
    left: 44px;
    opacity: 0;
    width: 0;
    height: 0;
    overflow: hidden;
    transition: 0.5s;
    z-index: 2;
    h6{
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #252525;
      margin: 0;
    }
    p{
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #B4B4B4;
    }
   }
   &:hover{
    .hover-box-open {
      opacity: 1;
      width: 317px;
      height: auto;
      padding: 22px;
    }
   }
}

/*table*/
.table{
  thead{
    tr{
      background: #ED7954;
      box-shadow: 0px 10px 30px rgb(0 0 0 / 15%);
      border: none;
      th{

        font-weight: 600;
        font-size: 18px;
        color: #FFFFFF;
        vertical-align: middle;
        padding: 14px 10px;

        &:first-child{
          border-radius: 13px 0px 0px 0px;
        }
        &:last-child{
          border-radius: 0px 13px 0px 0px;
        }
        img{
          width: auto;
          display: unset;
        }
        .dropdown-select {
          position: relative;
          span{
            border: none;
            color: #fff;
            font-size: 18px;
            font-weight: 600;
            cursor: pointer;
          }
          .open-dropdown {
            position: absolute;
            width: 0;
            background: #FFFFFF;
            box-shadow: 0px 4px 26px rgb(0 0 0 / 10%);
            border-radius: 10px;
            right: 0;
            left: 0;
            overflow: hidden;
            opacity: 0;
            height: 0;
            transition: 0.5s;
            margin: auto;
            z-index: 2;
            h6{
              font-weight: 600;
              font-size: 14px;
              color: #ED7954;
              padding: 0px 14px;
            }
            ul{
              list-style: none;
              li{
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: #252525;
                border-bottom: 1px solid #EBEBEB;
                text-align: left;
                padding: 10px 14px;
                margin-bottom: 0;
                cursor: pointer;
                &:hover{
                  color: #ED7954;
                }
                &:last-child{
                  border-bottom: none;
                }
              }
            }
          }
          &:hover{
            .open-dropdown {
              width: 132px;
              opacity: 1;
              height: auto;
            }
          }
        }
      }
    }
  }
  tbody{
    border-top: none !important;
    tr{
      border: none;
      td{
        img{
          width: auto;
          display: unset;
        }
        font-weight: 500;
        font-size: 16px;
        color: #252525;
        border-bottom: 1px solid #DDDDDD;
        vertical-align: middle;
        padding: 14px 10px;
        .user-img {
          position: relative;
          cursor: pointer;
          span,img{
            width: 45px;
            height: 45px;
            border-radius: 40px;
            color: #fff;
          }
          .hover-box-open {
            background: #FFFFFF;
            box-shadow: 0px 4px 40px rgb(0 0 0 / 24%);
            border-radius: 12px;
            padding: 0;
            position: absolute;
            text-align: left;
            left: 44px;
            opacity: 0;
            width: 0;
            height: 0;
            overflow: hidden;
            transition: 0.5s;
            z-index: 2;
            h6{
              font-weight: 500;
              font-size: 20px;
              line-height: 24px;
              color: #252525;
              margin: 0;
            }
            p{
              font-weight: 500;
              font-size: 14px;
              line-height: 17px;
              color: #B4B4B4;
            }
           }
           &:hover{
            .hover-box-open {
              opacity: 1;
              width: 317px;
              height: auto;
              padding: 22px;
            }
           }
        }
        .link-text{
          width: 400px;
          a{
            text-decoration-line: underline;
            color: #0094FF;
            word-break: break-all
          }
          span{
            cursor: pointer;
          }
        }
       .form-control {
          border: 1px solid #ED7954;
          border-radius: 8px;
          padding: 9px 18px;
          font-size: 16px;
          &:focus{
            outline: none;
            box-shadow: none;
          }
        }
        .btn-primary {
          background: #ED7954;
          border-radius: 10px;
          font-weight: 600;
          font-size: 14px;
          padding: 10px 30px;
          border-color: #ED7954;
          &:hover,&:focus{
            background: #fff;
            border-color: #ED7954;
            color: #ED7954;
            box-shadow: none;
          }
         }
         .edit{
          text-decoration-line: underline;
          color: #ED7954;
         }
      }
      &:last-child{
        td{
          border: none;
        }
      }
    }
  }
}

/**/
.pagination{
  ul{
    li{
      list-style: none;
      margin: 0px 4px ;
      span{
        user-select: none;
        font-weight: 500;
        font-size: 16px;
        color: #252525;
        width: 50px;
        height: 38px;
        border: 1px solid #B6B6B6;
        border-radius: 8px;
        cursor: pointer;
        &:hover{
          color: #ED7954;
        }
      }
      &.active{
        span{
          background-color: #ED7954;
          color: #fff;
          border-color:  #ED7954;
        }
      }
    }
  }
}
}

/*program edit*/
.site-change-modal .modal-inner {
  min-width: 800px;
}

.fade.modal-backdrop.show {
  z-index: 2;
}

.tabs-programs{
  border-radius: 22px;
   .nav-pills{
    .nav-item{
       list-style: none;
      .nav-tabbar {
        border: 0px ;
        border-bottom: 3px solid transparent;
        border-radius: 0;
        font-size: 18px;
        font-weight: 500;
        background: transparent;
        color: #000;
        cursor: pointer;
        &:focus{
          outline: none;
          box-shadow: none;
        }
        &.active{
          border-color:  #ED7954;
          color: #ED7954 ;
        }
      }
     }
   }
   form{
    .form-label {
       color: #000;
       b{
        color: #ED7954 ;
       }
     }
    .form-control {
      border-radius: 6px;
      padding: 12px 14px;
      font-size: 14px;
      &:focus, &:hover{
        outline: none;
        box-shadow: none;
        border-color: #ED7954;
      }
     }
     .btn-primary {
      color: #fff;
      background-color: #ED7954;
      border-color: #ED7954;
      padding: 12px 51px;
      font-size: 18px;
      font-weight: 500;
      &:focus,&:hover{
        box-shadow: none;
        color: #ED7954;
      background-color: #fff;
      }
     }
     .btn-outline-primary {
      color: #ED7954;
      border: none;
      font-size: 16px;
      &:hover,&:focus{
        background: none;
        color: #e96e45;
        box-shadow: none;
      }
     }
   }
   .accordion-item {
      border: none;
      .accordion-header {
        margin-top: 3px;
       }
      .accordion-button{
        padding-left: 35px;
         &:not(.collapsed) {
          color: #ED7954;
          background: transparent;
          box-shadow: none;
          &::after {
            background-image: url(./assets/images/open-acc.png);
          }
       }
       &:focus{
         outline: none;
         box-shadow: none;
       }
       &::after {
        background-image: url(./assets/images/add.png);
        position: absolute;
        left: 0;
      }
      }
   }
}
